<template>
  <b-modal
    v-model="localActive"
    hide-footer
    centered
    header-bg-variant="info"
    header-text-variant="light"
    title="Solicitar Adelanto (Contratante)"
  >
    <div class="row">
      <div class="col-12 mt-3">
        <p>
          Puede solicitar un máximo de S/.{{
            item.totalPrecioContratista.toFixed(2)
          }}
        </p>
        <label for="nro-operacion"
          ><code>Monto a Solicitar (en Nuevos Soles)</code>:</label
        >
        <b-input-group prepend="S/." class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            v-model="form.monto"
            id="nro-operacion"
            type="number"
            @keypress="regexNumber"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <hr />

    <div class="col-12 text-right mt-3">
      <button
        :disabled="loading"
        type="button"
        class="btn btn-link"
        @click="closeModal"
      >
        Cerrar Cuadro
      </button>

      <button
        @click="$bvModal.show('modal-confirmacion-firma')"
        class="btn btn-info"
        :disabled="loading || $v.$invalid"
      >
        <template v-if="loading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only"></span>

          <span class="d-inline-block ml-2">Subiendo</span>
        </template>

        <span v-else>Solicitar Adelanto</span>
      </button>
    </div>

    <b-modal
      id="modal-confirmacion-firma"
      header-bg-variant="success"
      header-text-variant="light"
      title="Confirmar Solicitud de monto"
      hide-footer
      centered
    >
      <div class="row">
        <div class="col-12">
          <p class="text-center mb-2">
            Por favor confirme esta acción nuevamente
          </p>
          <p class="text-center mb-2">
            <code
              >Esta a punto de solicitar un adelanto fijo para realizar el
              contrato, por favor verificar que el monto sea el correcto y este
              bien escrito.</code
            >
          </p>
        </div>
        <div class="col-12 text-right mt-3">
          <button
            :disabled="loading || $v.$invalid"
            type="button"
            class="btn btn-link"
            @click="$bvModal.hide('modal-confirmacion-firma')"
          >
            Cerrar Cuadro
          </button>
          <button
            @click="submit"
            class="btn btn-info"
            :disabled="loading || $v.$invalid"
          >
            <template v-if="loading">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"></span>

              <span class="d-inline-block ml-2">Subiendo</span>
            </template>

            <span v-else>Confirmar Solicitud de Adelanto</span>
          </button>
        </div>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
// import estadoMixins from "@/mixins/estadoMixins.js";

import { validationMixin } from "vuelidate";
const { required, maxValue } = require("vuelidate/lib/validators");
import regexMixin from "@/mixins/regexMixins";

import CreateAdelantoContratista from "@/apollo/mutations/tareas/CreateAdelantoContratista.gql";

const defaultForm = {
  codigoTicket: null,
  monto: null,
  estado: 0,
  fecha: null,
  asignacionId: null,
  contratistaId: null,
};

export default {
  props: {
    active: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
  },
  mixins: [validationMixin, regexMixin],
  computed: {
    localActive: {
      get() {
        if (this.active) {
          this.form = { ...defaultForm };
        }
        return this.active;
      },
      set(valBoolean) {
        this.$emit("on-modal", valBoolean);
      },
    },
  },
  data() {
    return {
      id: this.item.codigoTicket,
      form: { ...defaultForm },
      loading: false,
    };
  },
  validations() {
    return {
      form: {
        monto: { required , maxValue: maxValue(this.item.totalPrecioContratista)},
      },
    };
  },
  methods: {
    submit() {
      const actualDate = new Date().toISOString().slice(0, 10);
      this.form = {
        ...this.form,
        fecha: actualDate,
        codigoTicket: this.item.codigoTicket,
        asignacionId: this.item.asignacionId,
        estado: 0,
        contratistaId: this.$store.state.datosUsuarioLogueado.userId,
      };

      this.$apollo
        .mutate({
          mutation: CreateAdelantoContratista,
          variables: {
            input: this.form,
          },
          errorPolicy: "all",
        })
        .then((res) => {
          if (res.errors) {
            this.loading = false;

            const h = this.$createElement;

            var vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` El adelanto no se ha podido solicitar, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            var vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Adelanto no solicitado")]
            );

            // EN CASO HAYA SIDO SOLICITADO EL ADELANTO
            if (res.errors[0].debugMessage == "ADELANTO_YA_ASIGNADO") {
              vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` El adelanto ya ha sido solicitado, por favor espere la respuesta del administrador `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);

              vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "ADELANTO YA SOLICITADO.")]
              );

              this.$bvModal.hide("modal-confirmacion-firma");
              this.closeModal();
            }

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          } else {
            this.$toast.success(
              `Adelanto de S/.${this.form.monto ? Number(this.form.monto).toFixed(2) : this.form.monto} Solicitado correctamente, por favor espere la respuesta del administrador.`,
              {
                // override the global option
                position: "top-right",
              }
            );

            this.$bvModal.hide("modal-confirmacion-firma");
            this.closeModal();
            this.$emit("on-finished");
          }
        });
    },

    closeModal() {
      this.localActive = false;
      this.form = { ...defaultForm };
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-image {
  width: 120px;
  object-fit: cover;
}
</style>
