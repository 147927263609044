<template>
  <b-modal
    v-model="localActive"
    hide-footer
    centered
    header-bg-variant="primary"
    header-text-variant="light"
    title="Subir Factura"
  >
    <div class="row">
      <div class="col-12">
        <vue-dropzone
          ref="dropzoneImage"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-complete="setImageURLPreview"
        ></vue-dropzone>
      </div>
      <div v-if="archivo" class="col-12 mt-3">
        <hr />
        <p class="text-center d-flex align-items-center justify-content-center">
          <img
            class="placeholder-image mr-3"
            :src="isImage ? archivo.urlPreview : pdf"
          />
          {{ filename }}
        </p>
      </div>
    </div>
    <hr />

    <div class="col-12 text-right mt-3">
      <button
        :disabled="loading"
        type="button"
        class="btn btn-link"
        @click="closeModal"
      >
        Cerrar Cuadro
      </button>

      <button
        @click="$bvModal.show('modal-confirmacion-firma')"
        class="btn btn-info"
        :disabled="loading || $v.$invalid"
      >
        <template v-if="loading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only"></span>

          <span class="d-inline-block ml-2">Subiendo</span>
        </template>

        <span v-else>Subir Archivo</span>
      </button>
    </div>

    <b-modal
      id="modal-confirmacion-firma"
      header-bg-variant="warning"
      title="Confirmar Subida de Factura"
      hide-footer
      centered
    >
      <div class="row">
        <div class="col-12">
          <p class="text-center mb-2">
            Por favor confirme esta acción nuevamente
          </p>
          <p class="text-center mb-2">
            <code
              >Lo que debe subir exclusivamente debe ser la factura o una foto
              nítida de esta.</code
            >
          </p>
        </div>
        <div class="col-12 text-right mt-3">
          <button
            :disabled="loading || $v.$invalid"
            type="button"
            class="btn btn-link"
            @click="$bvModal.hide('modal-confirmacion-firma')"
          >
            Cerrar Cuadro
          </button>
          <button
            @click="uploadFile"
            class="btn btn-info"
            :disabled="loading || $v.$invalid"
          >
            <template v-if="loading">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"></span>

              <span class="d-inline-block ml-2">Subiendo</span>
            </template>

            <span v-else>Confirmar Subida</span>
          </button>
        </div>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
// import estadoMixins from "@/mixins/estadoMixins.js";
import CreateImage from "@/apollo/mutations/imagenes/CreateImage.gql";

import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import UpdateAsignacion from "@/apollo/mutations/asignaciones/UpdateAsignacion.gql";

import UpdateFacturaReconocimientoLocal from "@/apollo/mutations/asignaciones/UpdateFacturaReconocimientoLocal.gql";
import estadoMixins from "@/mixins/estadoMixins.js";

export default {
  props: {
    active: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
  },
  mixins: [validationMixin, estadoMixins],
  computed: {
    isImage() {
      switch (this.extension) {
        case "jpg":
        case "png":
        case "jpeg":
          return true;

        default:
          return false;
      }
    },
    localActive: {
      get() {
        if (this.active) {
          this.archivo = null;
          this.filename = "";
          this.extension = "";
        }
        return this.active;
      },
      set(valBoolean) {
        this.$emit("on-modal", valBoolean);
      },
    },
  },
  data() {
    return {
      id: this.item.codigoTicket,

      archivo: null,
      rawFile: null,
      pdf: require("@/assets/imagenes/pdf.svg"),
      image: require("@/assets/imagenes/picture.svg"),
      filename: "",
      extension: "",
      loading: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        maxFiles: 1,
        thumbnailWidth: 145,
        thumbnailHeight: 145,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png, .pdf",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastre o haga click aquí</p>
                              <p class="form-text">Archivos permitidos: .jpg, .jpeg, .png o .pdf</p>
                            `,
      },
    };
  },
  validations: {
    archivo: { required },
  },
  methods: {
    privateActualizarEstadoAsignacion(nuevoEstado) {
      // Backend no permite actualizar estado si no es admin
      let {
        codigoTicket,
        descripcionServicio,
        contratistaId,
        supervisorId,
        finanzasId,
        localId,
        fechaInicioOriginal,
        fechaInicioVigente,
        fechaPago,
        latitud,
        longitud,
      } = this.item;

      this.$apollo.mutate({
        mutation: UpdateAsignacion,
        variables: {
          input: {
            codigoTicket,
            descripcionServicio,
            estado: nuevoEstado,
            contratistaId,
            supervisorId,
            finanzasId,
            localId,
            fechaInicioOriginal,
            fechaInicioVigente,
            fechaPago,
            latitud,
            longitud,
          },
        },
      });
    },
    uploadFile() {
      // SE SUBIRA LA IMAGEN CON EL THIS.ITEM COMO BASE
      // this.privateUploadImages().then(async () => {
      // --- LOGICA DE ACTUALIZACION DEL SISTEMA---

      this.$apollo
        .mutate({
          mutation: UpdateFacturaReconocimientoLocal,
          errorPolicy: "all",
          variables: {
            input: {
              codigoTicket: this.id,
            },
            documentoFactura: this.rawFile,
          },
        })
        .then(async (res) => {
          if (res.errors) {
            this.loading = false;

            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` La asignación no se ha podido actualizar, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          } else {
            await this.privateActualizarEstadoAsignacionCodigo(15, this.item);

            this.$bvModal.hide("modal-confirmacion-firma");
            this.closeModal();
            this.$emit("on-finished");
          }
        });
      // });
    },
    privateUploadImages() {
      return new Promise((resolveFunction) => {
        this.$apollo
          .mutate({
            mutation: CreateImage,
            errorPolicy: "all",
            variables: {
              imagen: this.archivo.imagen,
              nombre: this.archivo.descripcion,
            },
          })
          .then((resImage) => {
            if (resImage.errors) {
              this.loading = false;
              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` La imagen no se pudo subir, por favor intentelo nuevamente. `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "La imagen no se pudo subir")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
              return;
            }

            // SI LO SUBE CORRECTAMENTE
            this.archivo = resImage.data.CreateImage.id;
            resolveFunction();
          });
      });
    },
    closeModal() {
      this.localActive = false;
      this.archivo = null;
      this.filename = "";
      this.extension = "";
    },
    setImageURLPreview(file) {
      let archivo = {
        imagen: file,
        urlPreview: URL.createObjectURL(file),
        descripcion: file.name,
      };

      this.rawFile = file;

      this.archivo = { ...archivo };
      this.filename = file.name;
      this.extension = file.name.split(".").pop();

      this.$refs.dropzoneImage.removeFile(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-image {
  width: 100px;
  object-fit: cover;
}
</style>
