var mixins = {
  methods: {
    //   Solicitud de Cotización
    first(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.privateActualizarEstadoAsignacionCodigo(2, item);
        this.$router.push({
          name: "asignacion-detalle-segundo",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";

        // this.resting();
        this.setToastMessage(title, message);
      }
    },

    // Visita Supervisor
    second(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.$router.push({
          name: "asignacion-detalle-segundo",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();
        this.setToastMessage(title, message);
      }
    },

    // Asignación de partidas (Supervisor)
    third(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.$router.push({
          name: "asignacion-detalle-tercero",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();
        this.setToastMessage(title, message);
      }
    },

    // Pendiente Estimación (Contratista)
    fourth(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 3) {
        this.$router.push({
          name: "asignacion-detalle-cuarto",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();
        this.setToastMessage(title, message);
      }
    },

    // Pendiente Estimación (Administrador)
    fifth(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 1) {
        this.$router.push({
          name: "asignacion-detalle-quinto",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";

        // this.resting();
        this.setToastMessage(title, message);
      }
    },

    // Pendiente Aprobación (Administrador)
    sixth(item) {
      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        ` Para poder continuar se necesita que el administrador apruebe la asignación. `,
        h("b-spinner", { props: { type: "grow", small: true } }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        {
          class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
        },
        [
          h(
            "strong",
            { class: "mr-2" },
            "Necesita Aprobación del Administrador"
          ),
        ]
      );

      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: "info",
        time: 5000,
      });

      // // this.resting();

      return;
    },

    // Aprobado - Contratista debe iniciar
    seventh(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 3) {
        this.$router.push({
          name: "asignacion-detalle-septimo",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();

        this.setToastMessage(title, message);
      }
    },

    //Ejecutado - Pendiente Revisión (Supervisor)
    eighth(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.$router.push({
          name: "asignacion-detalle-octavo",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();

        this.setToastMessage(title, message);
      }
    },

    // Corrección Pendiente (Contratista)
    ninth(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 3) {
        this.$router.push({
          name: "asignacion-detalle-septimo",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();

        this.setToastMessage(title, message);
      }
    },

    // Fotos Finales (Supervisor)
    tenth(item) {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.$router.push({
          name: "asignacion-detalle-noveno",
          params: { id: item.codigoTicket },
        });
      } else {
        const title = "Aún no puede continuar la asignación";
        const message =
          "No tiene los accesos, debe esperar a que la asignación este en un estado valido para su intervención";
        // this.resting();

        this.setToastMessage(title, message);
      }
    },

    // Trabajo Completado
    resting(item) {
      setTimeout(()=>{
        this.$router.push({
          name: "asignacion-detalles-id",
          params: { id: item.codigoTicket },
        });
      }, 3000)
    },

    rejected() {
      const title = "La asignación fue rechazada";
      const message =
        "No tienes acceso a la vista original, debido a que el proyecto fue anulado";
      this.setToastMessage(title, message, "danger");
    },

    // DEFAULT
    notHandled() {
      // this.resting();

      // const h = this.$createElement;

      // const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
      //   h("b-spinner", { props: { type: "grow", small: true } }),
      //   ` El estado actual no dispone de una vista, fijarse en los botones en la sección Asignaciones `,
      //   h("b-spinner", { props: { type: "grow", small: true } }),
      // ]);
      // // Create the title
      // const vNodesTitle = h(
      //   "div",
      //   {
      //     class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
      //   },
      //   [h("strong", { class: "mr-2" }, "Este estado no tiene vista")]
      // );

      // this.$bvToast.toast([vNodesMsg], {
      //   title: [vNodesTitle],
      //   solid: true,
      //   variant: "info",
      // });
      // return;
    },

    // BASE MESSAGE
    setToastMessage(
      title = "No title",
      message = "No message inserted",
      color = "info"
    ) {
      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        ` ${message} `,
        h("b-spinner", { props: { type: "grow", small: true } }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        {
          class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
        },
        [h("strong", { class: "mr-2" }, `${title}`)]
      );

      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: `${color}`,
        time: 5000,
      });
      return;
    },
  },
};

export default mixins;
