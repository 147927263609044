<template>
  <Default>
    <div class="usuarios">
      <banner
        :fondo="KanbanBG"
        titulo="Asignaciones"
        descripcion="Lista de Asignaciones registradas"
      ></banner>

      <section class="main__content">
        <div class="card">
          <div class="card-header row no-gutters">
            <section class="d-md-flex col-12 col-md-3">
              <div class="d-flex align-items-center">
                <span class="small text-muted mr-2">Mostrar</span>

                <select
                  @change="changeAmountPaginate"
                  class="form-control form-control-sm nro-items"
                  v-model="numberPaginate"
                  placeholder="Cantidad a mostrar"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </section>

            <input
              type="search"
              placeholder="Buscar por ID o nombre"
              class="form-control search col-12 col-md-4 mt-2 mt-md-0"
              v-model="query"
              @change="searchByQuery"
            />

            <router-link
              v-if="datosUsuarioLogueado.tipoUsuario === 1"
              :to="{ name: 'asignaciones-crear' }"
              class="btn btn-add-asignacion btn-primary btn-sm col-12 offset-md-1 col-md-4 mt-2 mt-md-0"
            >
              <i class="fas fa-plus mr-2"></i>
              Registrar Asignación
            </router-link>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-custom">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      <span class="small">Cód. Ticket</span>
                    </th>
                    <th scope="col">
                      <span class="small">Estado</span>
                    </th>
                    <th scope="col">
                      <span class="small">Usuario</span>
                    </th>
                    <th scope="col">
                      <span class="small">Local</span>
                    </th>
                    <th scope="col">
                      <span class="small">Supervisor</span>
                    </th>
                    <th scope="col">
                      <span class="small">Contratista</span>
                    </th>
                    <th scope="col">
                      <span class="small">Finanzas</span>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="item in items" :key="item.asignacionId">
                    <td data-label="Código Ticket" scope="row">
                      <span class="small">{{ item.codigoTicket }}</span>
                    </td>
                    <td data-label="Estado" scope="row">
                      <b-badge
                        class="px-4 py-2"
                        pill
                        :variant="getEstado(item)[1]"
                        >{{ getEstado(item)[0] }}</b-badge
                      >
                    </td>
                    <td data-label="Usuario" scope="row">
                      <span class="small">{{ getEstado(item)[2] }}</span>
                    </td>
                    <td data-label="Local">
                      <p class="small mt-2 mb-1">
                        {{ item.local.nomLocal }}
                      </p>
                    </td>
                    <td data-label="Supervisor">
                      <p class="small mt-2 mb-1">
                        {{ item.supervisor.nombres }}
                        {{ item.supervisor.apellidos }}
                      </p>
                    </td>
                    <td data-label="Contratista">
                      <p class="small mt-2 mb-1">
                        {{ item.contratista.nombres }}
                        {{ item.contratista.apellidos }}
                      </p>
                    </td>
                    <td data-label="Finanzas">
                      <p class="small mt-2 mb-1">
                        {{ item.fianza.nombres }}
                        {{ item.fianza.apellidos }}
                      </p>
                    </td>
                    <td data-label="Acciones" class="text-right">
                      <!-- AVANZAR ASIGNACION -->
                      <button
                        v-if="canShow(item)"
                        @click="nextLogic(item)"
                        class="btn btn-success btn-sm d-inline-block ml-2"
                        v-b-tooltip.hover
                        title="AVANZAR ASIGNACIÓN"
                      >
                        <i class="fas fa-chevron-circle-right"></i>
                      </button>
                      <!-- VERIFICAR ADELANTO ADELANTO -->
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        v-b-tooltip.hover
                        @click="openVerificarAdelanto(item)"
                        title="Verificar Adelanto"
                        v-if="verificarAdelanto(item)"
                      >
                        <i class="fas fa-donate"></i>
                      </button>

                      <!-- SOLICITAR ADELANTO -->
                      <button
                        type="button"
                        class="btn btn-info btn-sm ml-2"
                        v-b-tooltip.hover
                        @click="openSolicitarAdelanto(item)"
                        title="Solicitar Adelanto"
                        v-if="validarAdelanto(item)"
                      >
                        <i class="fas fa-donate"></i>
                      </button>

                      <!-- SUBIR FACTURA -->
                      <button
                        type="button"
                        class="btn btn-danger btn-sm ml-2"
                        v-b-tooltip.hover
                        @click="openUploadFacturaModal(item)"
                        title="Subir Factura"
                        v-if="(isAdmin || isFinanzas) && item.estado == 14"
                      >
                        <i class="fas fa-money-check-alt"></i>
                      </button>

                      <!-- SUBIR ORDEN DE COMPRA -->
                      <button
                        type="button"
                        class="btn btn-info btn-sm ml-2"
                        v-b-tooltip.hover
                        @click="openUploadOrdenCompraModal(item)"
                        title="Subir Orden de Compra"
                        v-if="(isAdmin || isFinanzas) && item.estado == 13"
                      >
                        <i class="fas fa-receipt"></i>
                      </button>

                      <!-- SUBIR VOUCHER DE PAGO -->
                      <button
                        type="button"
                        class="btn btn-warning btn-sm ml-2"
                        v-b-tooltip.hover
                        @click="openUploadVoucherModal(item)"
                        title="Subir Voucher de Pago"
                        v-if="(isAdmin || isFinanzas) && item.estado == 12"
                      >
                        <i class="fas fa-file-invoice-dollar"></i>
                      </button>

                      <!-- SUBIR DOCUMENTO FIRMADO -->
                      <button
                        type="button"
                        class="btn btn-warning btn-sm ml-2"
                        v-b-tooltip.hover
                        @click="openUploadFileModal(item)"
                        title="Subir OT"
                        v-if="isSupervisor && item.estado == 11"
                      >
                        <i class="fas fa-cloud-upload-alt"></i>
                      </button>

                      <!-- GENERAR PRIMER PDF -->
                      <button
                        type="button"
                        class="btn btn-success btn-sm ml-2"
                        v-b-tooltip.hover.noninteractive
                        title="Generar PDF de tiempos y costos"
                        @click="getFirstPDF(item)"
                        v-if="isAdmin && firstPDFValidation(item)"
                      >
                        <template v-if="item.loadingFirstPDF">
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only"></span>
                        </template>

                        <i v-else class="fas fa-file-pdf"></i>
                      </button>

                      <!-- <b-tooltip
                      target="tooltip-button-not-interactive"
                      noninteractive
                      ></b-tooltip
                    > -->

                      <!-- GENERAR SEGUNDO PDF -->
                      <button
                        type="button"
                        class="btn btn-dark btn-sm ml-2"
                        v-b-tooltip.hover.noninteractive
                        title="Generar Reporte y Conformidad de Trabajo"
                        @click="getSecondPDF(item)"
                        v-if="
                          (isAdmin || isSupervisor) && secondPDFValidation(item)
                        "
                      >
                        <template v-if="item.loadingSecondPDF">
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only"></span>
                        </template>

                        <i v-else class="fas fa-file-signature"></i>
                      </button>

                      <!-- CAMBIAR ESTADO DE FORMA MANUAL -->
                      <button
                        type="button"
                        class="btn btn-warning btn-sm ml-2"
                        v-b-tooltip.hover
                        title="Cambiar estado"
                        @click="cambiarEstadoModal(true, item)"
                        v-if="isAdmin && item.estado < 7"
                      >
                        <i class="fas fa-dice"></i>
                      </button>

                      <!-- VER DETALLES DE LA ASIGNACION -->
                      <router-link
                        :to="{
                          name: 'asignacion-detalle-primero',
                          params: { id: item.codigoTicket },
                        }"
                        class="btn btn-info btn-sm d-inline-block ml-2"
                        v-b-tooltip.hover
                        title="Ver Asignación"
                      >
                        <i class="fas fa-info-circle"></i>
                      </router-link>

                      <!-- ELIMINAR ASIGNACIÓN -->
                      <button
                        type="button"
                        class="btn btn-danger btn-sm ml-2"
                        v-b-tooltip.hover
                        title="Eliminar"
                        @click="cambiarEstadoModalEliminar(true, item)"
                        v-if="isAdmin && item.estado != 1000 && item.estado < 7"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="i in 10" :key="i">
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                  </tr>
                </tbody>
              </table>

              <p
                class="text-muted text-center mt-4"
                v-if="items.length == 0 && !loading"
              >
                No existen asignaciones registradas
              </p>
            </div>

            <div class="mt-3 d-flex justify-content-end">
              <div class="overflow-auto">
                <b-pagination
                  v-model="page"
                  :total-rows="nroItems"
                  :per-page="numberPaginate"
                  @change="getPages"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- MODAL DE CAMBIAR ESTADOS -->
      <b-modal
        id="modal-cambiar-estado"
        title="Cambiar estado"
        hide-footer
        centered
      >
        <div class="text-center" v-if="itemSeleccionado">
          <p class="mt-4 h5">
            Indique a que estado desea actualizar la asignación
            <code>{{ itemSeleccionado.codigoTicket }}</code>
          </p>
          <p class="font-italic text-muted mb-4">
            Tener en cuenta que esta acción no se podrá deshacer
          </p>

          <v-select
            :options="estadosDisponibles"
            :reduce="(e) => e.value"
            label="title"
            :clearable="false"
            placeholder="Seleccione el nuevo estado a pasar"
            v-model="newState"
            class="mb-3"
          ></v-select>

          <hr />

          <div class="text-right">
            <button
              type="button"
              @click="changeState(itemSeleccionado, newState)"
              class="btn btn-warning"
              :disabled="!newState || loadingRefreshing"
            >
              <template v-if="loadingRefreshing">
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only"></span>

                <span class="d-inline-block ml-2">Editando</span>
              </template>

              <span v-else>Editar Asignación</span>
            </button>
          </div>
        </div>
      </b-modal>

      <!-- MODAL DE ELIMINAR ASIGNACION -->
      <b-modal
        id="modal-eliminar-producto"
        title="Eliminar Asignación"
        header-bg-variant="danger"
        header-text-variant="light"
        hide-footer
        centered
      >
        <div class="text-center" v-if="itemSeleccionado">
          <p class="my-4">
            ¿Está seguro de eliminar la asignación?
          </p>

          <div class="text-right">
            <button
              type="button"
              class="btn btn-link"
              @click="cambiarEstadoModalEliminar(false)"
            >
              Volver
            </button>

            <button
              type="button"
              class="btn btn-danger"
              :disabled="loadingDelete"
              @click="eliminar()"
            >
              <template v-if="loadingDelete">
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only"></span>

                <span class="d-inline-block ml-2">Eliminando</span>
              </template>

              <span v-else>Eliminar Asignación</span>
            </button>
          </div>
        </div>
      </b-modal>

      <!-- MODAL PARA SOLICITAR ADELANTO -->
      <modal-solicitar-adelanto
        v-if="asignationSelected"
        :active="solicitarAdelanto"
        :item="asignationSelected"
        @on-modal="solicitarAdelanto = $event"
        @on-finished="getItems"
      ></modal-solicitar-adelanto>

      <!-- MODAL PARA VERIFICAR ADELANTO -->
      <modal-verificar-adelanto
        v-if="asignationSelected"
        :active="verificarAdelantoState"
        :item="asignationSelected"
        @on-modal="verificarAdelantoState = $event"
        @on-finished="getItems"
      ></modal-verificar-adelanto>

      <!-- MODAL SUBIR ORDEN DE COMPRA - ESTADO 13 -->
      <modal-subir-factura
        v-if="asignationSelected"
        :active="uploadFileFactura"
        :item="asignationSelected"
        @on-modal="uploadFileFactura = $event"
        @on-finished="getItems"
      ></modal-subir-factura>

      <!-- MODAL SUBIR ORDEN DE COMPRA - ESTADO 13 -->
      <modal-subir-orden-compra
        v-if="asignationSelected"
        :active="uploadFileOrdenCompra"
        :item="asignationSelected"
        @on-modal="uploadFileOrdenCompra = $event"
        @on-finished="getItems"
      ></modal-subir-orden-compra>

      <!-- MODAL SUBIR VOUCHER - ESTADO 12 -->
      <modal-subir-voucher
        v-if="asignationSelected"
        :active="uploadFileVoucher"
        :item="asignationSelected"
        @on-modal="uploadFileVoucher = $event"
        @on-finished="getItems"
      ></modal-subir-voucher>

      <!-- MODAL SUBIR ARCHIVO FIRMADO - ESTADO 11 -->
      <modal-subir-archivo-firmado
        v-if="asignationSelected"
        :active="uploadFileFirmed"
        :item="asignationSelected"
        @on-modal="uploadFileFirmed = $event"
        @on-finished="getItems"
      ></modal-subir-archivo-firmado>

      <!-- NO ENCONTRAMOS NADA -->
      <b-modal
        id="modal-1"
        centered
        hide-footer
        title="No encontramos la asignación"
        header-bg-variant="primary"
        header-text-variant="light"
      >
        <p class="my-4 h6">
          Lo sentimos, pero no hallamos una asignación con el código ingresado
        </p>
        <hr />
        <button
          type="button"
          class="btn btn-primary float-right"
          outline
          variant="primary"
          @click="$bvModal.hide('modal-1')"
        >
          Cerrar Cuadro
        </button>
      </b-modal>
    </div>
  </Default>
</template>

<script>
import { mapState } from "vuex";
import Default from "@/layouts/default.vue";

import { estados } from "../../../.env.js";
import availableEstadoMixin from "@/mixins/availableEstadoMixins.js";

import GetAllAsignaciones from "@/apollo/queries/asignaciones/GetAllAsignaciones.gql";
import GetAsignacion from "@/apollo/queries/asignaciones/GetAsignaciones.gql";
import DeleteAsignacion from "@/apollo/mutations/asignaciones/DeleteAsignacion.gql";

import GetPrimerPDF from "@/apollo/queries/asignaciones/GetPrimerPDFReconocimientoLocal.gql";
import GetFinalPDF from "@/apollo/queries/asignaciones/GetPdfReconocimientoLocalFinal.gql";

import estadoMixins from "@/mixins/estadoMixins.js";
import loadPaginate from "@/mixins/loadPaginate.js";

import Banner from "@/components/global/Banner";
import KanbanBG from "@/assets/banner/kanban.jpg";
import UpdateAsignacion from "@/apollo/mutations/asignaciones/UpdateAsignacion.gql";

import ModalSolicitarAdelanto from "@/components/asignaciones/modalSolicitarAdelanto.vue";
import ModalVerificarAdelanto from "@/components/asignaciones/modalVerificarAdelanto.vue";
import ModalSubirArchivoFirmado from "@/components/asignaciones/modalSubirArchivoFirmado.vue";
import ModalSubirVoucher from "@/components/asignaciones/modalSubirVoucher.vue";
import ModalSubirOrdenCompra from "@/components/asignaciones/modalSubirOrdenCompra.vue";
import ModalSubirFactura from "@/components/asignaciones/modalSubirFactura.vue";

import logicRoutingMixins from "@/mixins/logicRoutingButton.js";

export default {
  data() {
    return {
      items: [],
      query: "",
      numberPaginate: 10,
      page: 1,
      nroItems: 1,
      tipoUsuario: 1,
      itemSeleccionado: null,
      loadingRefreshing: false,
      loadingDelete: false,
      loading: false,
      KanbanBG: KanbanBG,
      estados: estados,
      estadosDisponibles: [],

      newState: null,

      asignationSelected: null,

      // ESTADO
      solicitarAdelanto: false,
      verificarAdelantoState: false,
      uploadFileFirmed: false,
      uploadFileVoucher: false,
      uploadFileOrdenCompra: false,
      uploadFileFactura: false,
    };
  },
  mixins: [
    availableEstadoMixin,
    estadoMixins,
    loadPaginate,
    logicRoutingMixins,
  ],
  mounted() {
    this.getItems();
  },
  components: {
    Banner,
    Default,
    ModalSubirArchivoFirmado,
    ModalSubirVoucher,
    ModalSubirOrdenCompra,
    ModalSubirFactura,
    ModalSolicitarAdelanto,
    ModalVerificarAdelanto,
  },
  methods: {
    getEstado(item) {
      let estado = this.estados.find((e) => e.value == item.estado);

      return [estado["title"], estado["variant"], estado["user"]];
    },

    canShow(item) {
      switch (item.estado) {
        case 1:
          return this.datosUsuarioLogueado.tipoUsuario === 2;

        case 2:
          return this.datosUsuarioLogueado.tipoUsuario === 2;

        case 3:
          return this.datosUsuarioLogueado.tipoUsuario === 2;

        case 4:
          return this.datosUsuarioLogueado.tipoUsuario === 3;

        case 5:
          return this.datosUsuarioLogueado.tipoUsuario === 1;

        case 6:
          // return this.datosUsuarioLogueado.tipoUsuario === 2
          return false;

        case 7:
          return this.datosUsuarioLogueado.tipoUsuario === 3;

        case 8:
          return this.datosUsuarioLogueado.tipoUsuario === 2;

        case 9:
          return this.datosUsuarioLogueado.tipoUsuario === 3;

        case 10:
          return this.datosUsuarioLogueado.tipoUsuario === 2;
        //  return false

        case 1000:
          return false;

        default:
          return false;
      }
    },

    nextLogic(item) {
      /* VER EL ARCHIVO .env.js PARA TENER NOCION DE LOS ESTADOS */
      switch (item.estado) {
        case 1:
          this.first(item);
          break;

        case 2:
          this.second(item);
          break;

        case 3:
          this.third(item);
          break;

        case 4:
          this.fourth(item);
          break;

        case 5:
          this.fifth(item);
          break;

        case 6:
          this.sixth(item);
          break;

        case 7:
          this.seventh(item);
          break;

        case 8:
          this.eighth(item);
          break;

        case 9:
          this.ninth(item);
          break;

        case 10:
          this.tenth(item);
          break;

        case 1000:
          this.rejected(item);
          break;

        default:
          this.notHandled();
          break;
      }
    },

    getPages(page) {
      this.loading = true;

      this.query = "";
      this.verificarAdelantoState = false;
      this.solicitarAdelanto = false;
      this.uploadFileFactura = false;
      this.uploadFileOrdenCompra = false;
      this.uploadFileVoucher = false;
      this.uploadFileFirmed = false;
      this.asignationSelected = false;

      this.page = Number(page);

      this.loadItems(
        GetAllAsignaciones,
        this.numberPaginate,
        page,
        "",
        this.loading
      ).then((response) => {
        setTimeout(() => {
          this.items = [];
          this.nroItems = response.data.GetAllAsignaciones.NroItems;

          this.items = response.data.GetAllAsignaciones.data;
          this.loading = false;

          this.items = this.items.map(
            (t) =>
              (t = { ...t, loadingFirstPDF: false, loadingSecondPDF: false })
          );
        }, 1);
      });

      this.$router.replace({ query: { page } });
    },

    changeAmountPaginate() {
      this.$router.replace({ query: { page: 1 } });
      this.page = 1;
      this.getItems();
    },

    searchByQuery() {
      if (this.query == "" || this.query == null) {
        this.changeAmountPaginate();
      } else {
        this.$apollo
          .query({
            query: GetAsignacion,
            variables: { codigoTicket: this.query },
            errorPolicy: "all",
          })
          .then((res) => {
            console.log(res);
            if (res.errors) {
              // ERROR DE BUSQUEDA
              this.$bvModal.show("modal-1");
              this.query = "";
              return;
            }
            this.items = [];
            setTimeout(() => {
              this.items.push(res.data.GetAsignaciones);
            }, 1);
          });
      }
    },

    getItems() {
      this.$nextTick(() => {
        this.loading = true;
        this.query = "";
        this.verificarAdelantoState = false;

        this.solicitarAdelanto = false;
        this.uploadFileFactura = false;
        this.uploadFileOrdenCompra = false;
        this.uploadFileVoucher = false;
        this.uploadFileFirmed = false;
        this.asignationSelected = false;

        this.loadItems(
          GetAllAsignaciones,
          this.numberPaginate,
          this.page,
          "",
          this.loading
        ).then((response) => {
          this.items = [];

          setTimeout(() => {
            // PARA AFUERA

            this.$nextTick(() => {
              this.nroItems = response.data.GetAllAsignaciones.NroItems;

              this.items = response.data.GetAllAsignaciones.data;
              this.loading = false;

              this.items = this.items.map(
                (t) =>
                  (t = {
                    ...t,
                    loadingFirstPDF: false,
                    loadingSecondPDF: false,
                  })
              );
            });

            setTimeout(() => {
              this.$nextTick(() => {
                if (this.$route.query.page) {
                  const page = this.$route.query.page;
                  this.page = Number(page);
                }
              });
            }, 2);

            // FIN PARA AFUERA
          }, 1);
        });
      });
    },

    verificarAdelanto(item) {
      return (
        (this.isAdmin || this.isFinanzas) &&
        item.AdelantoContratista &&
        item.AdelantoContratista.estado == 0 &&
        item.estado < 13
      );
    },

    validarAdelanto(item) {
      return (
        this.isContratant &&
        Number(item.estado) >= 7 &&
        Number(item.estado) < 13 &&
        item.AdelantoContratista == null
      );
    },

    openVerificarAdelanto(item) {
      this.asignationSelected = { ...item };
      this.verificarAdelantoState = true;
    },
    openSolicitarAdelanto(item) {
      this.asignationSelected = { ...item };
      this.solicitarAdelanto = true;
    },
    openUploadFacturaModal(item) {
      this.asignationSelected = { ...item };
      this.uploadFileFactura = true;
    },
    openUploadOrdenCompraModal(item) {
      this.asignationSelected = { ...item };
      this.uploadFileOrdenCompra = true;
    },
    openUploadVoucherModal(item) {
      this.asignationSelected = { ...item };
      this.uploadFileVoucher = true;
    },

    openUploadFileModal(item) {
      this.asignationSelected = { ...item };
      this.uploadFileFirmed = true;
    },
    async changeState(selectedItem, newState) {
      this.loadingRefreshing = true;

      // await this.privateActualizarEstadoAsignacionSeleccionada(
      //   selectedItem,
      //   newState
      // );

      // Backend no permite actualizar estado si no es admin
      let {
        descripcionServicio,
        direccionLocal,
        latitud,
        longitud,

        contratistaId,
        supervisorId,
        finanzasId,
        localId,
        tiempoEntrega,
        ofertaValida,
        correoAtencion,
        celularAtencion,
        codigoTicket,
        observaciones,
        comentariosAdicionales,
        atencion,
        rango,
        fechaInicioOriginal,
        fechaInicioVigente,
        fechaPago,
        numerodeTecnicos,
        bono,
        costoAdicional,
        nroOperacion,
      } = selectedItem;

      this.$apollo
        .mutate({
          mutation: UpdateAsignacion,
          variables: {
            input: {
              codigoTicket,
              descripcionServicio,
              direccionLocal,
              latitud,
              longitud,
              estado: newState,
              contratistaId,
              supervisorId,
              finanzasId,
              localId,
              tiempoEntrega,
              ofertaValida,
              correoAtencion,
              celularAtencion,

              observaciones,
              comentariosAdicionales,
              atencion,
              rango,
              fechaInicioOriginal,
              fechaInicioVigente,
              fechaPago,
              numerodeTecnicos,
              bono,
              costoAdicional,
              nroOperacion,
            },
          },
        })
        .then((res) => {
          this.loadingRefreshing = false;
          this.$bvModal.hide("modal-cambiar-estado");
          this.getItems();
        });
    },
    cambiarEstadoModal(booleanValue, item) {
      this.newState = null;

      this.estadosDisponibles = this.setAvailableEstado(item.estado);

      if (booleanValue) {
        this.itemSeleccionado = item;
        this.$bvModal.show("modal-cambiar-estado");
      } else {
        this.$bvModal.hide("modal-cambiar-estado");
        this.itemSeleccionado = null;
        this.newState = null;
      }
    },
    cambiarEstadoModalEliminar(mostrar, item) {
      if (mostrar) {
        this.itemSeleccionado = item;

        this.$bvModal.show("modal-eliminar-producto");
      } else {
        this.$bvModal.hide("modal-eliminar-producto");

        this.itemSeleccionado = null;
      }
    },
    eliminar() {
      this.loadingDelete = true;

      let codigoTicket = this.itemSeleccionado.codigoTicket;

      this.$apollo
        .mutate({
          mutation: DeleteAsignacion,
          variables: {
            input: {
              codigoTicket,
            },
          },
        })
        .then(() => {
          this.eliminarPorIndice();

          this.loadingDelete = false;

          this.cambiarEstadoModalEliminar(false);

          this.$bvToast.toast("Asignación eliminada", {
            // title: `Variant ${variant || "default"}`,
            variant: "success",
            solid: true,
          });
        });
    },
    eliminarPorIndice() {
      let index = this.items.indexOf(this.itemSeleccionado);

      this.items.splice(index, 1);

      this.itemSeleccionado = null;
    },
    getFirstPDF(item) {
      item.loadingFirstPDF = true;
      this.$apollo
        .query({
          query: GetPrimerPDF,
          fetchPolicy: "no-cache",
          errorPolicy: "all",
          variables: {
            codigoTicket: item.codigoTicket,
          },
        })
        .then((res) => {
          item.loadingFirstPDF = false;

          if (res.errors) {
            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` No se puede abrir el PDF, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Ha ocurrido un error")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          }
          const ruta = res.data.GetPdfReconocimientoLocal;
          window.open(ruta, "_blank").focus();
        });
    },
    getSecondPDF(item) {
      item.loadingSecondPDF = true;
      this.$apollo
        .query({
          query: GetFinalPDF,
          fetchPolicy: "no-cache",
          errorPolicy: "all",
          variables: {
            codigoTicket: item.codigoTicket,
          },
        })
        .then((res) => {
          item.loadingSecondPDF = false;

          if (res.errors) {
            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` No se puede abrir el PDF, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Ha ocurrido un error")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          }

          const ruta = res.data.GetPdfReconocimientoLocalFinal;
          window.open(ruta, "_blank").focus();
        });
    },
    firstPDFValidation(item) {
      // VER LOS ESTADOS EN .env.js
      if (item.estado >= 6 && item.estado != 1000) return true;
      return false;
    },
    secondPDFValidation(item) {
      // VER LOS ESTADOS EN .env.js
      if (item.estado >= 12 && item.estado != 1000) return true;
      return false;
    },
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),

    isAdmin: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratant: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-asignacion {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nro-items {
  width: 100%;
}
.card-body {
  padding: 10px;
}
.usuarios {
  &__image {
    width: 40px;
    height: 40px;

    border-radius: 50%;
  }
}
</style>
