import { estados } from "../../.env.js";

var mixin = {
  data: () => ({
    estadosPrivateMixins: estados,
  }),
  methods: {
    setAvailableEstado(estadoActual) {
      const estadosPrivateMixins = this.$data.estadosPrivateMixins;

      estadoActual = Number(estadoActual);

      const estadoFound = estadosPrivateMixins.find(
        (t) => t.value == estadoActual
      );

      const disponibilidad = estadoFound.availableTo;

      let estadosDisponibles = [];

      estadosPrivateMixins.map((e) => {
        if (disponibilidad.includes(e.value)) {
          estadosDisponibles.push(e);
        }
      });

      return estadosDisponibles;
    },
  },
};

export default mixin;
