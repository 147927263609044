<template>
  <b-modal
    v-model="localActive"
    hide-footer
    centered
    header-bg-variant="info"
    header-text-variant="light"
    title="Subir Voucher de Pago"
  >
    <div class="row">
      <div class="col-12">
        <vue-dropzone
          ref="dropzoneImage"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-complete="setImageURLPreview"
        ></vue-dropzone>
      </div>
      <div v-if="archivo" class="col-12 mt-3">
        <hr />
        <p class="text-center d-flex align-items-center justify-content-center">
          <img
            class="placeholder-image mr-3"
            :src="isImage ? archivo.urlPreview : pdf"
          />
          {{ filename }}
        </p>
      </div>
      <div class="col-12 col-sm-10 offset-sm-1 mt-3  card">
        <div class="card-body">
          <p class="h6 font-weight-bold border-bottom">Datos de Contratista</p>

          <p>
            <span class="font-weight-bold text-uppercase">Nombre: </span>
            {{ item.contratista.nombres }}
          </p>
          <p>
            <span class="font-weight-bold text-uppercase">
              Apellido:
            </span>
            {{ item.contratista.apellidos }}
          </p>

          <p>
            <span class="font-weight-bold text-uppercase">Banco: </span>
            {{ item.contratista.banco }}
          </p>
          <p>
            <span class="font-weight-bold text-uppercase">
              Número de Cuenta:
            </span>
            {{ item.contratista.nroCuenta }}
          </p>
          <p>
            <span class="font-weight-bold text-uppercase">CCI: </span>
            {{ item.contratista.cci }}
          </p>
        </div>
      </div>
      <hr />
      <div class="col-12 mt-3">
        <label for="nro-operacion"><code>Nro. de Operación</code>:</label>
        <b-form-input
          v-model="nroOperacion"
          id="nro-operacion"
          type="text"
        ></b-form-input>
      </div>
      <div class="col-12 mt-3 mb-3">
        <div class="form-row">
          <b-input-group append="Fecha de Pago" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-datepicker
              id="example-datepicker"
              placeholder="Indica la fecha de de pago"
              v-model="fechaPago"
            ></b-form-datepicker>
          </b-input-group>
        </div>
      </div>
    </div>
    <hr />

    <div class="col-12 text-right mt-3">
      <button
        :disabled="loading"
        type="button"
        class="btn btn-link"
        @click="closeModal"
      >
        Cerrar Cuadro
      </button>

      <button
        @click="$bvModal.show('modal-confirmacion-firma')"
        class="btn btn-info"
        :disabled="loading || $v.$invalid"
      >
        <template v-if="loading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only"></span>

          <span class="d-inline-block ml-2">Subiendo</span>
        </template>

        <span v-else>Subir Archivo</span>
      </button>
    </div>

    <b-modal
      id="modal-confirmacion-firma"
      header-bg-variant="success"
      header-text-variant="light"
      title="Confirmar Subida de Voucher"
      hide-footer
      centered
    >
      <div class="row">
        <div class="col-12">
          <p class="text-center mb-2">
            Por favor confirme esta acción nuevamente
          </p>
          <p class="text-center mb-2">
            <code
              >Lo que debe subir exclusivamente debe ser el voucher o una foto
              nítida de este y que el número de operación sea el indicado.</code
            >
          </p>
        </div>
        <div class="col-12 text-right mt-3">
          <button
            :disabled="loading || $v.$invalid"
            type="button"
            class="btn btn-link"
            @click="$bvModal.hide('modal-confirmacion-firma')"
          >
            Cerrar Cuadro
          </button>
          <button
            @click="uploadFile"
            class="btn btn-info"
            :disabled="loading || $v.$invalid"
          >
            <template v-if="loading">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"></span>

              <span class="d-inline-block ml-2">Subiendo</span>
            </template>

            <span v-else>Confirmar Subida</span>
          </button>
        </div>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
// import estadoMixins from "@/mixins/estadoMixins.js";
import CreateImage from "@/apollo/mutations/imagenes/CreateImage.gql";

import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import UpdateVoucherReconocimientoLocal from "@/apollo/mutations/asignaciones/UpdateVoucherReconocimientoLocal.gql";
import UpdateAsignacion from "@/apollo/mutations/asignaciones/UpdateAsignacion.gql";
import estadoMixins from "@/mixins/estadoMixins.js";
import baseReconocimiento from "@/mixins/baseReconocimiento.js";

export default {
  props: {
    active: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
  },
  mixins: [validationMixin, estadoMixins, baseReconocimiento],
  computed: {
    isImage() {
      switch (this.extension) {
        case "jpg":
        case "png":
        case "jpeg":
          return true;

        default:
          return false;
      }
    },
    localActive: {
      get() {
        if (this.active) {
          this.archivo = null;
          this.filename = "";
          this.extension = "";
        }
        return this.active;
      },
      set(valBoolean) {
        this.$emit("on-modal", valBoolean);
      },
    },
  },
  data() {
    return {
      archivo: null,
      rawFile: null,
      id: this.item.codigoTicket,

      nroOperacion: "",
      fechaPago: null,
      pdf: require("@/assets/imagenes/pdf.svg"),
      image: require("@/assets/imagenes/picture.svg"),
      filename: "",
      extension: "",
      loading: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        maxFiles: 1,
        thumbnailWidth: 145,
        thumbnailHeight: 145,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png, .pdf",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastre o haga click aquí</p>
                              <p class="form-text">Archivos permitidos: .jpg, .jpeg, .png o .pdf</p>
                            `,
      },
    };
  },
  validations: {
    archivo: { required },
    nroOperacion: { required },
    fechaPago: { required },
  },
  methods: {
    privateActualizarEstadoAsignacion(nuevoEstado) {
      // Backend no permite actualizar estado si no es admin
      let {
        codigoTicket,
        descripcionServicio,
        contratistaId,
        supervisorId,
        finanzasId,
        localId,
        fechaInicioOriginal,
        fechaInicioVigente,
        latitud,
        longitud,
      } = this.item;

      this.$apollo.mutate({
        mutation: UpdateAsignacion,
        variables: {
          input: {
            codigoTicket,
            descripcionServicio,
            contratistaId,
            supervisorId,
            finanzasId,
            estado: nuevoEstado,
            localId,
            fechaInicioOriginal,
            fechaInicioVigente,
            fechaPago: this.fechaPago,
            nroOperacion: this.nroOperacion,
            latitud,
            longitud,
          },
        },
      });
    },
    uploadFile() {
      this.archivo.descripcion = this.nroOperacion;
      // SE SUBIRA LA IMAGEN CON EL THIS.ITEM COMO BASE
      // this.privateUploadImages().then(async () => {
      // --- LOGICA DE ACTUALIZACION DEL SISTEMA---

      let item = {
        ...this.item,
        nroOperacion: this.nroOperacion,
        fechaPago: this.fechaPago,
      };
      this.updateReconocimientoLocal(item, this.id).then((res) => {
        if (res.errors) {
          this.loading = false;

          const h = this.$createElement;

          const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
            h("b-spinner", { props: { type: "grow", small: true } }),
            ` La asignación no se ha podido actualizar, intentelo nuevamente `,
            h("b-spinner", { props: { type: "grow", small: true } }),
          ]);
          // Create the title
          const vNodesTitle = h(
            "div",
            {
              class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
            },
            [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
          );

          this.$bvToast.toast([vNodesMsg], {
            title: [vNodesTitle],
            solid: true,
            variant: "danger",
          });
        } else {
          this.$apollo
            .mutate({
              mutation: UpdateVoucherReconocimientoLocal,
              errorPolicy: "all",
              variables: {
                input: {
                  codigoTicket: this.id,
                  nroOperacion: this.nroOperacion,
                },
                voucher: this.rawFile,
              },
            })
            .then(async (res) => {
              if (res.errors) {
                this.loading = false;

                const h = this.$createElement;

                const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                  h("b-spinner", { props: { type: "grow", small: true } }),
                  ` La asignación no se ha podido actualizar, intentelo nuevamente `,
                  h("b-spinner", { props: { type: "grow", small: true } }),
                ]);
                // Create the title
                const vNodesTitle = h(
                  "div",
                  {
                    class: [
                      "d-flex",
                      "flex-grow-1",
                      "align-items-baseline",
                      "mr-2",
                    ],
                  },
                  [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
                );

                this.$bvToast.toast([vNodesMsg], {
                  title: [vNodesTitle],
                  solid: true,
                  variant: "danger",
                });
              } else {
                await this.privateActualizarEstadoAsignacionCodigo(13, item);

                this.$bvModal.hide("modal-confirmacion-firma");
                this.closeModal();
                this.$emit("on-finished");
              }
            });
        }
      });

      // });
    },
    privateUploadImages() {
      return new Promise((resolveFunction) => {
        this.$apollo
          .mutate({
            mutation: CreateImage,
            errorPolicy: "all",
            variables: {
              imagen: this.archivo.imagen,
              nombre: this.archivo.descripcion,
            },
          })
          .then((resImage) => {
            if (resImage.errors) {
              this.loading = false;
              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` La imagen no se pudo subir, por favor intentelo nuevamente. `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "La imagen no se pudo subir")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
              return;
            }

            // SI LO SUBE CORRECTAMENTE
            this.archivo = resImage.data.CreateImage.id;
            resolveFunction();
          });
      });
    },
    closeModal() {
      this.localActive = false;
      this.archivo = null;
      this.filename = "";
      this.extension = "";
    },
    setImageURLPreview(file) {
      let archivo = {
        imagen: file,
        urlPreview: URL.createObjectURL(file),
        descripcion: "",
      };

      this.rawFile = file;

      this.archivo = { ...archivo };
      this.filename = file.name;
      this.extension = file.name.split(".").pop();

      this.$refs.dropzoneImage.removeFile(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-image {
  width: 120px;
  object-fit: cover;
}
</style>
